'use client';
import {useEffect, useState} from "react";
import Image, {StaticImageData} from 'next/image';
import {cn} from "@/lib/utils";

export default function ImageWithFallback({
                                              fallback = '/placeholder-image.webp',
                                              alt,
                                              src,
                                              isLetter = false,
                                              isGradient = false,
                                              width, height,
                                              ...rest
                                          }: {
    fallback?: StaticImageData | string,
    alt: string,
    isLetter?: boolean,
    isGradient?: boolean,
    src?: StaticImageData | string,
    width?: number,
    height?: number,
    [_: string | number | symbol]: unknown;
}) {
    const [error, setError] = useState<boolean>()

    useEffect(() => {
        setError(undefined)
    }, [src])

    return (
        <>
            {
                (isLetter && (error || !src)) ? (<div
                    className={'rounded-full h-full w-full bg-primary text-white uppercase grid place-items-center worksans'}>
                    <strong>{alt.slice(0, 1)}</strong></div>) : (isGradient && (error || !src)) ?
                    (<div
                        className={cn(width && `w-[${width}px]`, 'h-full bg-gradient-to-tr from-primary to-tertiary')}/>) :
                    (<Image
                        quality={100}
                        alt={alt}
                        width={width}
                        height={height}
                        onError={() => setError(true)}
                        src={(error || src === undefined) ? fallback : src}
                        {...rest}
                    />)
            }
        </>
    )
}