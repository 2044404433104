import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import {PortableTextBlock, PortableTextTextBlock} from "@sanity/types";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function getSlugFromType(type: string, slug: string): string {
    switch (type) {
        case 'article':
            return `/insight/${slug}`;
        case 'qaPost':
            return `/qa/${slug}`;
        case 'interview':
            return `/interview/${slug}`;
        case 'newsPost':
            return `/news/${slug}`;
        default:
            console.error('Type not found!', type, slug)
            throw new Error('Type not found!')
    }
}

export function arrayRange(start: number, stop: number, step: number) {
    return Array.from(
        {length: (stop - start) / step + 1},
        (value, index) => start + index * step
    );
}

export function convertPortableTextBlockToPlaintext(blocks: PortableTextBlock[]): string {
    return blocks
        // loop through each block
        .map(block => {
            // if it's not a text block with children,
            // return nothing
            if (block._type !== 'block' || !block.children) {
                return ''
            }
                // loop through the children spans, and join the
            // text strings
            else return (block as PortableTextTextBlock).children.map(child => child.text).join('')
        })
        // join the paragraphs leaving split by two linebreaks
        .join('\n\n')
}

export const questionStatusList = ['Entered', 'In Progress', 'Discarded', 'Published', 'Completed', 'Scheduled'] as const;
export const attributionList = ['NoFollow', 'DoFollow', 'Unknown', 'Unlinked', 'Sponsored'] as const;
export const articleTypeList = ['Quotes Only', 'Article'] as const;
